define("strada-gui/controllers/inspections/index", ["exports", "strada-gui/gql/mutations/create-inspection", "strada-gui/gql/mutations/update-inspection", "strada-gui/gql/mutations/delete-inspection", "ember-apollo-client/mixins/route-query-manager", "moment"], function (exports, _createInspection, _updateInspection, _deleteInspection, _routeQueryManager, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    currentUser: Ember.inject.service(),
    queryParams: ["page", "query", "order"],
    page: 1,
    query: "",
    order: "EXECUTION_AT_DESC",

    newDialog: false,
    editDialog: false,
    newErrorMessage: false,
    editErrorMessage: false,

    actions: {
      openInspection: function openInspection(inspection) {
        switch (inspection.status) {
          case "DRAFT":
            this.transitionToRoute("reports.index", inspection);
            break;
          case "FINAL":
            this.transitionToRoute("reports.index", inspection, {
              queryParams: { status: "open" }
            });
            break;
          case "ASSIGNED":
            this.transitionToRoute("reports.index", inspection, {
              queryParams: { status: "assigned" }
            });
            break;
          case "SOLVED":
            this.transitionToRoute("reports.index", inspection, {
              queryParams: { status: "solved" }
            });
            break;
          case "ARCHIVED":
            this.transitionToRoute("reports.index", inspection);
            break;
          default:
          // code block
        }
      },
      updateOrder: function updateOrder(newOrder) {
        this.set("order", newOrder);
      },
      selectPage: function selectPage(page) {
        window.scroll(0, 0);
        this.set("page", page);
      },
      closeNewDialog: function closeNewDialog() {
        this.set("newDialog", false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set("editErrorMessage", null);
        this.set("editDialog", false);
      },
      closeDeleteDialog: function closeDeleteDialog() {
        this.set("deleteInspection", null);
        this.set("deleteDialog", false);
      },
      showNewDialog: function showNewDialog(company) {
        this.set("newDialog", true);
        this.set("newInspection", Ember.Object.create({
          name: "",
          executionAt: (0, _moment.default)(new Date()).format("YYYY-MM-DD"),
          status: "draft",
          companyId: company.id
        }));
      },
      showEditDialog: function showEditDialog(record) {
        this.set("editDialog", true);
        this.set("newInspection", Ember.Object.create(record));
      },
      showDeleteDialog: function showDeleteDialog(inspection) {
        this.set("deleteDialog", true);
        this.set("deleteInspection", inspection);
      },
      create: function create() {
        var newInspection = this.get("newInspection");
        var self = this;

        var variables = newInspection.getProperties("name", "executionAt", "companyId");
        this.get("apollo").mutate({
          mutation: _createInspection.default,
          variables: variables,
          refetchQueries: ["inspections"]
        }).then(function () {
          self.set("newErrorMessage", false);
          self.set("newDialog", false);
        }).catch(function (error) {
          self.set("newErrorMessage", error);
        });
      },
      update: function update() {
        var newInspection = this.get("newInspection");
        var self = this;
        var variables = newInspection.getProperties("id", "name", "status", "executionAt");
        this.get("apollo").mutate({
          mutation: _updateInspection.default,
          variables: variables,
          refetchQueries: ["inspections"]
        }).then(function () {
          self.set("editErrorMessage", false);
          self.set("editDialog", false);
        }).catch(function () {
          self.set("editErrorMessage", "Er is iets misgegaan");
        });
      },
      delete: function _delete(record) {
        var message = "Weet je het zeker? De inspectie wordt definitief verwijderd.";
        if (confirm(message)) {
          var self = this;
          var variables = { id: record.id };
          this.get("apollo").mutate({
            mutation: _deleteInspection.default,
            variables: variables,
            refetchQueries: ["inspections"]
          }).catch(function (error) {
            self.set("editErrorMessage", error);
          });
        }
      },
      archiveInspection: function archiveInspection(record) {
        var status = "ARCHIVED";
        var message = "Weet je het zeker? Door het de inspectie te archiveren kan je geen wijzigingen meer doorvoeren.";
        var answer = confirm(message);

        if (answer) {
          var self = this;
          var variables = {
            id: record.id,
            name: record.name,
            executionAt: record.executionAt,
            status: status
          };
          variables["status"] = status;
          this.get("apollo").mutate({
            mutation: _updateInspection.default,
            variables: variables,
            refetchQueries: ["inspections"]
          }).then(function () {
            self.set("editErrorMessage", false);
            self.set("editDialog", false);
          }).catch(function (error) {
            self.set("editErrorMessage", error);
          });
        }
      },
      destroy: function destroy(record) {
        record.destroyRecord();
      }
    }
  });
});